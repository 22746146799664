import React, { Component } from "react"
import { theme } from "../../styles/global-styles"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import ARPUCalculator from "./ARPUCalculator"

import { create, all } from "mathjs"
const config = {}
const math = create(all, config)

const MetricSectionWrapper = styled.section`
  width: 100%;
  background-color: ${theme.colors.dark};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: fit-content(100%);
  margin-bottom: 4rem;
  form {
    grid-column: 1/3;
    grid-row: 2/5;
    align-self: center;
    justify-self: center;
  }
  @media only screen and (max-width: 960px) {
    grid-template-rows: fit-content(100%);
    form {
      grid-column: 1/4;
      grid-row: 3/4;
    }
  }
`
const MetricSectionHeader = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${theme.colors.accentOne};
  grid-column: 1 / 3;
  grid-row: 1/2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  h6 {
    color: ${theme.colors.light};
    margin: 0;
    line-height: 1;
  }
  @media only screen and (max-width: 960px) {
    grid-column: 1/4;
    grid-row: 1/2;
  }
`
const CalculationSection = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${theme.colors.main};
  color: white !important;
  grid-column: 3/4;
  grid-row: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
  h6 {
    margin: 0;
  }
  @media only screen and (max-width: 960px) {
    display: none;
  }
`
const ResultsSection = styled.div`
  width: 100%;
  /* height: 100%; */
  grid-column: 3/4;
  grid-row: 2/5;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: ${theme.fontFaces.headerFont};
    font-size: 2rem;
    color: ${theme.colors.dark};
    line-height: 1;
    margin: 0;
    padding: 2rem;
    background-color: ${theme.colors.main};
  }
  @media only screen and (max-width: 960px) {
    grid-column: 1/4;
    grid-row: 4/5;
    background-color: ${theme.colors.main};
    p {
      background: none;
      padding: 1rem;
    }
  }
`

export default class ARPU extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      users: "",
      revenue: "",
      errorInEntry: "",
      result: false,
    }
  }

  handleChange = e => {
    const target = e.target
    let value = target.value
    const name = target.name
    if (value !== value) {
      this.setState({
        errorInEntry:
          "This calculation requires you to enter numbers only (decimals [1.00] are okay)",
      })
    } else {
      this.setState({ errorInEntry: false })
    }
    console.log("I am name and value in ARPU: ", name, value)
    this.setState({
      [name]: value,
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    let rev = parseInt(this.state.revenue)
    let usr = parseInt(this.state.users)
    const longNum = math.evaluate(rev / usr)
    const shortNum = math.round(longNum, 2)
    console.log("I am the longNum: ", longNum)
    console.log("I am the shortNum: ", shortNum)
    this.setState({
      revenue: "",
      users: "",
      errorInEntry: "",
      result: `ARPU = $${shortNum}`,
    })
  }
  //delete to here
  render() {
    const { errorInEntry, users, revenue } = this.state
    const { name, abbr, calculation } = this.props
    return (
      <MetricSectionWrapper>
        <MetricSectionHeader>
          <h6>{name} Calculator</h6>
        </MetricSectionHeader>
        <CalculationSection>
          <h6>{calculation}</h6>
        </CalculationSection>
        <ARPUCalculator
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          errorInEntry={errorInEntry}
          users={users}
          revenue={revenue}
          abbr={abbr}
        />
        <ResultsSection>
          <p>{this.state.result ? this.state.result : `${abbr} = $...`}</p>
        </ResultsSection>
      </MetricSectionWrapper>
    )
  }
}
