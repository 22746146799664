import React, { Component } from "react"
import { theme } from "../../styles/global-styles"
import styled from "@emotion/styled"

const JMForm = styled.form`
  padding: 1rem;

  button {
    background-color: ${theme.colors.main};
    color: ${theme.colors.dark};
    border: none;
    height: 2rem;
    padding: 0 1rem 0 1rem;
    line-height: 1;
    font-size: 0.8rem;
    font-family: ${theme.fontFaces.headerFont};
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
`

const JmInput = styled.input`
  height: 2rem;
  width: 100%;
  padding-left: 1rem;
  margin: 0 2rem 1rem 0;
  :first-of-type {
    margin-top: 1rem;
  }
  color: ${theme.colors.dark};
  background-color: ${theme.colors.accentOne};
  border: none;
  font-size: 0.7em;
  line-height: 1;
  font-family: ${theme.fontFaces.bodyFont};
  :last-of-type {
    margin-right: 0;
  }
  ::placeholder {
    color: white;
  }
`
const ValMessage = styled.p`
  color: ${theme.colors.accentTwo};
  line-height: 1;
  margin: 0;
`

const CACCalculator = ({
  handleSubmit,
  handleChange,
  errorInEntry,
  customers,
  costs,
  abbr,
}) => {
  return (
    <>
      <JMForm onSubmit={e => handleSubmit(e)}>
        <>{errorInEntry ? <ValMessage>{errorInEntry}</ValMessage> : <></>}</>
        <JmInput
          onChange={e => handleChange(e)}
          type="number"
          placeholder={`Enter total costs marketing and sales during a period`}
          value={costs ? costs : ""}
          name="costs"
        />
        <JmInput
          onChange={e => handleChange(e)}
          type="number"
          value={customers ? customers : ""}
          placeholder={`Enter number of newly acquired customers during same period`}
          name="customers"
        />
        <button type="submit">Calculate {abbr}</button>
      </JMForm>
    </>
  )
}

export default CACCalculator
