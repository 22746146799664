import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import MainNav from "../components/MainNav"
import Footer from "../components/Footer"
import FullWidthHero from "../components/FullWidthHero"
import MarketingArticle from "../components/MarketingArticle"
import { theme } from "../styles/global-styles"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const JavaScript = () => {
  return (
    <Layout>
      <SEO title="The top 5 Marketing Metrics You Should Be Measuring" />
      <MainNav
        logoAndMenuColor={theme.colors.light}
        bgColor={theme.colors.dark}
      />
      <FullWidthHero
        backgroundColor={theme.colors.dark}
        headerColor={theme.colors.light}
        headerText={
          "The Top Marketing Metrics Your CEO Wishes You Were Reporting"
        }
      />
      <MarketingArticle />
      <Footer />
    </Layout>
  )
}

export default JavaScript
