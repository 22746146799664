import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { theme } from "../styles/global-styles"
import { useStaticQuery, graphql } from "gatsby"
import SyntaxHighlighter from "react-syntax-highlighter"
import CR from "./calculators/CR"
import ARPU from "./calculators/ARPU"
import CAC from "./calculators/CAC"
import LTV from "./calculators/LTV"
import CRR from "./calculators/CRR"

import mySVG from "../images/JMBackground-01.svg"
import mySVGAccentOne from "../images/JMBackground-AccentOne.svg"

const StaticArticleWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StaticArticle = styled.article`
  width: 100%;
  margin: 4em;
  max-width: ${theme.widths.mainContainer};
  @media only screen and (max-width: 959px) {
    max-width: ${theme.widths.smContainer};
  }
  @media only screen and (max-width: 599px) {
    margin: 1.5em;
  }
`
const StaticArticleText = styled.div`
  padding: 2rem 0;
`

const StaticArticleDate = styled.h6``

const SectionBreak = styled.section`
  background-color: ${props => props.bgColor};
  width: 100%;
`

const BackgroundImage = styled.div`
  background-image: url(${props => props.bgImage});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const AbbrSpan = styled.span`
  color: ${theme.colors.accentOne};
`

const MarketingArticle = props => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "JMBackground.png" }) {
        id
        childImageSharp {
          fixed {
            src
            srcSet
          }
          fluid {
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <>
      <StaticArticleWrapper>
        <StaticArticle>
          <StaticArticleText>
            <h4
              css={css`
                margin-top: 0;
              `}
            >
              Conversion Rate — <AbbrSpan>CR</AbbrSpan>
            </h4>
            <p>
              For many organizations, especially e-commerce, Conversion Rate is
              the main metric that defines marketing success. It’s also one of
              the most misused due to it being leveraged for decision making but
              with a sample size that's insufficient to enable meaningful
              assumptions.
            </p>
            <CR
              name={"Conversion Rate"}
              abbr={"CR"}
              calculation={"(Conversions / Sessions) * 100"}
            />
            <h4
              css={css`
                margin-top: 0;
              `}
            >
              Customer Acquisition Costs — <AbbrSpan>CAC</AbbrSpan>
            </h4>
            <p>
              For many organizations, especially e-commerce, Conversion Rate is
              the main metric that defines marketing success. It’s also one of
              the most misused due to it being leveraged for decision making but
              with a sample size that's insufficient to enable meaningful
              assumptions.
            </p>
            <CAC
              name={"Customer Acquisition Costs"}
              abbr={"CAC"}
              calculation={"Marketing & Sales Costs / New Customers"}
            />
            <h4
              css={css`
                margin-top: 0;
              `}
            >
              Average Revenue Per User — <AbbrSpan>ARPU</AbbrSpan>
            </h4>
            <p>
              For many organizations, especially e-commerce, Conversion Rate is
              the main metric that defines marketing success. It’s also one of
              the most misused due to it being leveraged for decision making but
              with a sample size that's insufficient to enable meaningful
              assumptions.
            </p>
            <ARPU
              name={"Average Revenue Per User"}
              abbr={"ARPU"}
              calculation={"Total Revenue / Active Users"}
            />
            <h4
              css={css`
                margin-top: 0;
              `}
            >
              Customer Retention Rate — <AbbrSpan>CRR</AbbrSpan>
            </h4>
            <p>
              To start calculating your customer retention rate, select the time
              period you want to measure. Seven days, 30 days, 60 days etc...
            </p>
            <CRR
              name={"Customer Retention Rate"}
              abbr={"CRR"}
              calculation={"((End Total - New) / Start Total) * 100"}
            />
            <h4
              css={css`
                margin-top: 0;
              `}
            >
              Customer Lifetime Value — <AbbrSpan>LTV</AbbrSpan>
            </h4>
            <p>
              For many organizations, especially e-commerce, Conversion Rate is
              the main metric that defines marketing success. It’s also one of
              the most misused due to it being leveraged for decision making but
              with a sample size that's insufficient to enable meaningful
              assumptions.
            </p>
            <LTV
              name={"Customer Lifetime Value"}
              abbr={"LTV"}
              calculation={"Marketing & Sales Costs / New Customers"}
            />
          </StaticArticleText>
        </StaticArticle>
      </StaticArticleWrapper>

      <SectionBreak bgColor={theme.colors.main}>
        <BackgroundImage bgImage={mySVG}>
          <StaticArticle>
            <h3
              css={css`
                color: white;
                margin-top: 0;
              `}
            >
              Some Sort of Header Element Here. Something that We can Write
              here.
            </h3>
            <p
              css={css`
                color: white;
                margin-bottom: 0;
              `}
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Similique, recusandae. Exercitationem, eos, dignissimos expedita
              accusamus omnis laudantium quisquam in mollitia odio doloremque
              enim ea fugiat iste reiciendis nesciunt, aliquam pariatur?
            </p>
          </StaticArticle>
        </BackgroundImage>
      </SectionBreak>

      <SectionBreak bgColor={theme.colors.dark}>
        <BackgroundImage bgImage={mySVGAccentOne}>
          <StaticArticle>
            <h3
              css={css`
                color: white;
                margin-top: 0;
              `}
            >
              Some Sort of Header Element Here. Something that We can Write
              here.
            </h3>
            <p
              css={css`
                color: white;
                margin-bottom: 0;
              `}
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Similique, recusandae. Exercitationem, eos, dignissimos expedita
              accusamus omnis laudantium quisquam in mollitia odio doloremque
              enim ea fugiat iste reiciendis nesciunt, aliquam pariatur?
            </p>
          </StaticArticle>
        </BackgroundImage>
      </SectionBreak>
    </>
  )
}

export default MarketingArticle
